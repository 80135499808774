import Header from "../components/Header";

const AboutUs = () => {
  return (
    <div className="dark:bg-slate-900">
      <Header />

      <section>
        <div className="container mx-auto">
          <div className="pt-16 w-full">
            <p className="text-5xl font-bold text-black dark:text-white ml-6 text-center">
              О нас
            </p>
          </div>
          <div className="mt-10 mx-10 sm:mx-20">
            <p className="font-light dark:text-white">
              <span className="ml-4"></span> Фастомат - это инновация на рынке
              технических решений для HoReCa и Retail, система, предназначенная
              для автоматизации бесконтактной выдачи готовых заказов клиентам.
              <br />
              <br />
              <span className="font-semibold">Как это работает?</span>
              <ul className="list-disc">
                <li>
                  Клиент делает заказ в вашем заведении, получает чек с QR-кодом
                  на нем и по готовности заказа забирает его из Фастомата по
                  QR-коду из чека.
                </li>
                <li>
                  После поднесения QR-кода к сканеру в Фастомате открывается
                  дверца с его заказом, клиент информируется подсветкой ячейки,
                  голосом и текстом на экране (в некоторых моделях) о том, где
                  он может забрать свой заказ.
                </li>
              </ul>
              <br />
              <span className="font-semibold">Фастомат можно внедрять:</span>
              <ul className="list-disc">
                <li>
                  интегрируя с существующей системой приема заказов на уровне
                  софта (заказ, например, из 1С может сразу передаваться в
                  Фастомат)
                </li>
                <li>
                  так и в виде решения «из коробки» – купил, поставил и сразу
                  можно начинать выдачу через Фастомат, используя QR-коды,
                  которые уже есть на кассовых чеках.
                </li>
              </ul>
            </p>
          </div>
        </div>
        <div className="flex container mx-auto mt-16 flex-wrap justify-center">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/fyOsDN_PWqk?autoplay=1&loop=1&mute=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            autoplay
          ></iframe>
        </div>
      </section>
      <div className="h-screen"></div>
    </div>
  );
};
export default AboutUs;
