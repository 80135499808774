import fastomatImg from "../assets/fastomat.png";
import { NavLink } from "react-router-dom";
import Header from "../components/Header";

const Home = () => {
  return (
    <div className="dark:bg-slate-900">
      <Header />
      <main>
        <section className="bg-wallpaper-background bg-no-repeat bg-contain w-full md:bg-cover relative overflow-hidden">
          <div className="container mx-auto z-10 relative pb-10">
            <div className="w-full flex pt-20 text-black md:text-white flex-wrap-reverse md:flex-nowrap dark:text-white">
              <div className="w-full h-full mx-5">
                <p className="mb-5 text-4xl font-semibold xl:text-5xl lg:text-4xl">
                  Систмемы выдачи online-заказов в HoReCa,
                  ПВЗ, offline-магазинах
                </p>
                <p className="mt-10">
                  <span className="ml-4"></span>
                  Система бесконтактной выдачи заказов
                  представляет собой автоматизированную
                  систему, которая позволяет клиентам
                  оформлять заказы из любого места,
                  используя приложение на телефоне или на
                  сайте, и забирать их в специальном
                  автоматизированном выдаче, который
                  устанавливается в удобных местах: в
                  офисах, магазинах, торговых центрах,
                  вокзалах и т.д. Система обеспечивает
                  безопасную и удобную выдачу готовых к
                  заказу товаров и услуг.
                </p>
                <div className="mt-7">
                  <div>
                    <NavLink to="/catalog" title="Fastomat">
                      <p className="rounded-full text-center sm:text-start cursor-pointer bg-black text-white md:bg-white md:text-black px-6 py-2 mt-4 w-full sm:w-fit font-semibold dark:bg-white dark:text-black">
                        Перейти в каталог
                      </p>
                    </NavLink>
                  </div>
                  <div>
                    <p className="rounded-full text-center sm:text-start cursor-pointer bg-fuchsia-500 hover:bg-fuchsia-600 text-white px-6 py-2 mt-4 w-full sm:w-fit font-semibold dark:bg-fuchsia-800 dark:hover:bg-fuchsia-900">
                      Позвоните мне
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full h-full flex justify-center">
                <img
                  className="w-9/12 sm:w-full"
                  src={fastomatImg}
                  alt="fastomat"
                />
              </div>
            </div>
          </div>
          <div className="bg-white h-[255px] z-0 w-[110%] absolute -left-4 top-[86%] rotate-[-4deg] skew-x-[-4deg] dark:bg-slate-900"></div>
        </section>

        <section>
          <div className="container mx-auto flex flex-col-reverse lg:flex-row items-center justify-center">
            <div className="lg:w-7/12 w-full flex justify-center">
              <iframe
                className="mt-5 lg:w-full lg:h-[500px] w-3/4 md:h-[300px] h-[200px] rounded-lg"
                src="https://www.youtube.com/embed/XKkmxM73BA0?si=rsHxpai3-qf9s6Nl"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="lg:w-5/12 w-full flex justify-center">
              <p className="text-blue-800 dark:text-blue-400 text-3xl underline hover:no-underline cursor-pointer font-semibold text-center">
                <NavLink
                  to={
                    "https://youtu.be/XKkmxM73BA0?si=wLSPblmpV6CPL4Sw"
                  }
                >
                  Что же такое фастомат?
                </NavLink>
              </p>
            </div>
          </div>
        </section>

        <section className="z-10 relative">
          <div className="container mx-auto py-20">
            <div className="grid grid-rows-3 lg:grid-cols-3 lg:grid-rows-1 md:grid-cols-2 md:grid-rows-2">
              <div className="flex flex-col justify-center w-8/12 mt-10 xl:mt-0 mx-auto">
                <div className="border-blue-600 border-b-2 dark:border-blue-400">
                  <p className="my-10 text-xl font-semibold text-blue-800 dark:text-blue-400">
                    Уменьшение времени ожидания,
                    безопасность и удобство
                  </p>
                </div>
                <div className="mt-4 text-gray-800 dark:text-gray-100">
                  <p>
                    Система бесконтактной выдачи заказов
                    обеспечивает удобство и защищенность
                    процесса заказа и получения товара.
                    После оформления заказа клиенту выдается
                    уникальный код, который он может
                    использовать для получения товара в
                    автоматизированном выдаче, где товар
                    хранится в ячейках. Код должен быть
                    введен на специальном терминале выдачи,
                    после чего ячейка с соответствующим
                    товаром открывается.
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-center w-8/12 mt-10 xl:mt-0 mx-auto">
                <div className="border-blue-600 border-b-2 dark:border-blue-400">
                  <p className="my-10 text-xl font-semibold text-blue-800 dark:text-blue-400">
                    Легкость интеграции и широкая область
                    приминения
                  </p>
                </div>
                <div className="mt-4 text-gray-800 dark:text-gray-100">
                  <p>
                    Система бесконтактной выдачи заказов
                    может быть использована в различных
                    областях, включая фаст-фуд, аптеки,
                    супермаркеты, банки, отделения связи и
                    т.д. Она повышает эффективность
                    обслуживания, уменьшает очереди,
                    сокращает время ожидания и минимизирует
                    риски заражения вирусами.
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-center w-8/12 lg:w-full mt-10 xl:mt-0 mx-auto">
                <div className="border-r-2 border-blue-800 border-b-2 dark:border-blue-400">
                  <p className="my-10 mr-5 text-xl font-semibold text-blue-800 dark:text-blue-400">
                    Мобальная интеграция и бесконтактность
                  </p>
                </div>
                <div className="mt-4 text-gray-800 dark:text-gray-100">
                  <p>
                    Пользователи могут сделать заказы онлайн
                    или через мобильное приложение, оплатить
                    их с помощью электронной карты или через
                    мобильный телефон, и получить готовый
                    заказ без необходимости контактировать с
                    продавцом.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer>
        <div className="w-full flex h-[20px] bg-blue-500">
          <div className="w-4/12 bg-blue-700 h-full skew-x-[-30deg]"></div>
          <div className="w-2/12 bg-pink-600 h-full skew-x-[-30deg]"></div>
          <div className="w-1/12 bg-blue-500 h-full skew-x-[-30deg]"></div>
          <div className="w-2/12 bg-yellow-400 h-full skew-x-[-30deg]"></div>
          <div className="w-2/12 bg-pink-400 h-full skew-x-[-30deg]"></div>
        </div>
      </footer>
    </div>
  );
};
export default Home;
