import FastomatItem from "./FastomatItem";
import Fastomat from "../store/fastomats";
import { observer } from "mobx-react-lite"

const FastomatList = () => {
  return (
    <>
      {Fastomat.List.map((e) => {
        return <FastomatItem {...e} key={e.id} />;
      })}
    </>
  );
};

export default observer(FastomatList);
