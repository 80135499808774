import "./App.css";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/HomePage";
import Catalog from "./pages/Catalog";
import AboutUs from "./pages/AboutUs";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/catalog" element={<Catalog />} />
      <Route path="/about" element={<AboutUs />} />
    </Routes>
  );
}

export default observer(App);
