import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ReactComponent as MenuImg } from "../assets/menu-dark.svg";
import { ReactComponent as MenuImgDark } from "../assets/menu-light.svg";
import modeSwitcherLightImg from "../assets/light-svgrepo-com.svg";
import { ReactComponent as MoonImg } from "../assets/moon-svgrepo-com.svg";
import { NavLink, useLocation } from "react-router-dom";
import { scrollToElement } from "../utils/scrollTo";
import { changeLight, handleThemeChange } from "../utils/changeLightMode";

const Header = ({ catalog }) => {
  const [isOpen, setOpen] = useState(false);
  useEffect(() => handleThemeChange(), []);
  const openMenu = () => {
    setOpen((prev) => !prev);
  };
  const location = useLocation().pathname;
  const executeScroll = () => {
    if (!catalog) return;
    scrollToElement(catalog);
  };
  return (
    <>
      <header
        className={
          location !== "/"
            ? `${
                isOpen ? "h-full" : "h-16"
              } z-10 relative dark:bg-slate-900 dark:text-white`
            : `${
                isOpen ? "h-full" : "h-16"
              } z-10 relative bg-fuchsia-600 dark:bg-fuchsia-800 text-white`
        }
      >
        <div className="container mx-auto flex justify-between h-full">
          <NavLink to="/" title="Fastomat">
            <div className="flex items-center cursor-pointer p-2 m-2">
              <p className="text-3xl font-bold">FM</p>
            </div>
          </NavLink>
          <div className="items-center hidden md:flex h-full gap-7 font-semibold text-sm">
            <NavLink
              to="/catalog"
              title="Fastomat"
              onClick={() => executeScroll()}
            >
              <div>
                <p>Каталог</p>
              </div>
            </NavLink>
            <div>
              <a href="https://take-yours.online/issuing-system/">Фастоматы</a>
            </div>
            <div>
              <a href="https://take-yours.online/issuing-system/">
                Как это работает
              </a>
            </div>
            <div>
              <a href="https://take-yours.online/issuing-system/">Отзывы</a>
            </div>
            <div>
              <a href="https://take-yours.online/issuing-system/">
                Обратная связь
              </a>
            </div>
            <NavLink to="/about">
              <div>
                <p>О нас</p>
              </div>
            </NavLink>
            <div>
              <img
                className="hidden dark:block w-7 cursor-pointer"
                src={modeSwitcherLightImg}
                onClick={() => changeLight()}
                alt="light swither"
              />
              <MoonImg
                className="block dark:hidden w-7 cursor-pointer"
                onClick={() => changeLight()}
                stroke={location !== "/" ? "black" : "white"}
                alt="light swither"
              />
            </div>
          </div>
          <div className="flex items-center justify-center gap-4 max-h-16 md:hidden">
            <img
              className="hidden dark:block w-7 cursor-pointer"
              src={modeSwitcherLightImg}
              onClick={() => changeLight()}
              alt="light swither"
            />
            <MoonImg
              className="block dark:hidden w-7 cursor-pointer"
              onClick={() => changeLight()}
              stroke={location !== "/" ? "black" : "white"}
              alt="light swither"
            />
            {location !== "/" ? (
              <>
                <MenuImg
                  className="hidden dark:block w-7 cursor-pointer"
                  onClick={() => openMenu()}
                  alt="menu swither"
                />
                <MenuImgDark
                  className="block dark:hidden w-7 cursor-pointer"
                  onClick={() => openMenu()}
                  alt="menu swither"
                />
              </>
            ) : (
              <MenuImg
                className="block w-7 cursor-pointer"
                onClick={() => openMenu()}
                alt="menu swither"
              />
            )}
          </div>
        </div>
        {isOpen && <MobileHeader catalog={catalog} />}
      </header>
    </>
  );
};

export default observer(Header);

const MobileHeader = ({ catalog }) => {
  const executeScroll = () => {
    if (!catalog) return;
    scrollToElement(catalog);
  };
  return (
    <div className="items-center justify-center h-full font-semibold text-sm w-full">
      <div className="py-3 cursor-pointer w-full h-full mx-auto hover:bg-slate-900 hover:text-white flex justify-center items-center">
        <NavLink
          to="/catalog"
          className="w-full h-full flex justify-center items-center"
          title="Fastomat"
          onClick={() => executeScroll()}
        >
          <p>Каталог</p>
        </NavLink>
      </div>
      <div className="py-3 cursor-pointer w-full h-full mx-auto hover:bg-slate-900 hover:text-white flex justify-center items-center">
        <a href="https://take-yours.online/issuing-system/">Фастоматы</a>
      </div>
      <div className="py-3 cursor-pointer w-full h-full mx-auto hover:bg-slate-900 hover:text-white flex justify-center items-center">
        <a href="https://take-yours.online/issuing-system/">Как это работает</a>
      </div>
      <div className="py-3 cursor-pointer w-full h-full mx-auto hover:bg-slate-900 hover:text-white flex justify-center items-center">
        <a href="https://take-yours.online/issuing-system/">Отзывы</a>
      </div>
      <div className="py-3 cursor-pointer w-full h-full mx-auto hover:bg-slate-900 hover:text-white flex justify-center items-center">
        <a href="https://take-yours.online/issuing-system/">Обратная связь</a>
      </div>
      <div className="py-3 cursor-pointer w-full h-full mx-auto hover:bg-slate-900 hover:text-white flex justify-center items-center">
        <NavLink
          to="/about"
          className="w-full h-full flex justify-center items-center"
        >
          <p>О нас</p>
        </NavLink>
      </div>
    </div>
  );
};
