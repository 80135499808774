import { makeAutoObservable } from "mobx";
import { configure } from "mobx";
import fastomat1Img from "../assets/fastomats/fastomat1.png";
import fastomat2Img from "../assets/fastomats/fastomat2.png";
import fastomat3Img from "../assets/fastomats/fastomat3.png";
import fastomat4Img from "../assets/fastomats/fastomat4.png";
import fastomat5Img from "../assets/fastomats/fastomat5.png";
import fastomat6Img from "../assets/fastomats/fastomat6.png";
import fastomat7Img from "../assets/fastomats/fastomat7.png";

configure({
  enforceActions: "never",
});

class Fastomat {
  List = [];
  constructor() {
    this.List = [
      {
        id: 0,
        name: "Фастомат 2х3 настольный",
        description: "none",
        price: 148000,
        image: fastomat1Img,
        basketCount: 0,
      },
      {
        id: 1,
        name: "Фастомат 1х6 настольный",
        description: "none",
        price: 148000,
        image: fastomat2Img,
        basketCount: 0,
      },
      {
        id: 2,
        name: "Фастомат 1х3 настольный",
        description: "none",
        price: 148000,
        image: fastomat3Img,
        basketCount: 0,
      },
      {
        id: 3,
        name: "Фастомат 2х6 настольный",
        description:
          "Настольная версия Фастомата в корпусе из нержавеющей стали с прозрачными дверцами, электронными замками, сканером кодов и экраном со стороны клиента, комплектация может быть дополнительно оснащена замками, сканером и экраном со стороны сборщика. ",
        price: 154000,
        image: fastomat4Img,
        basketCount: 0,
      },
      {
        id: 4,
        name: "Вертикальный Фастомат 2х4",
        description: "none",
        price: 150000,
        image: fastomat7Img,
        basketCount: 0,
      },
      {
        id: 5,
        name: "Вертикальный Фастомат 4+2",
        description: "none",
        price: 150000,
        image: fastomat6Img,
        basketCount: 0,
      },
    ];
    makeAutoObservable(this);
  }
}

export default new Fastomat();
